<template>
  <div class="row flex-column">
    <div class=" card m-0 p-0">
        <div class="card-sub-header">İlgili Kişiler</div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addPerson = true"
              >+ Yeni Kişi</b-button
            ></b-form-group
          >
        </div>
      </div>
    </div>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="İlgili Kişiler Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0" @click="openDeleteHandler">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addPerson" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Yeni İlgili Kişi</h4>
      </template>

      <div class="form-group">
        <label for="type">Kullanıcı</label>
        <MultiSelect
              :options="users"
              :multiple="false"
              v-model="interested_customer"
            />
        </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addPerson = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addPerson = false" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation modalid="deleteFeature" />

  </div>
  
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "propertySupport",
  data() {
    return {
      addPerson: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İsim Soyisim", value: "name" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      users:[],
      interested_customer:""
    };
  },

  created() {
    this.getList();
    this.getUsers();

  },
  methods: {
    openDeleteHandler(key) {
      this.$bvModal.show("modal-deleteOperationdeleteFeature");
    },
    getList() {
      this.loading = true;

      this.items = [
      ];
      this.totalItems = 3;

      this.pagination = {
        current_page: 1,
        total_pages: 1,
        total_items: 3,
      };
      this.loading = false;

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    userToSelect(data){
      let newUsers = []
        data.map((e) => {
          let userArray = {text: e.adi + " " + e.soyadi , value:e?._id?.$oid}
          newUsers.push(userArray)
        })
        return newUsers
    },
    getUsers() {
      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `user/user?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.status.code === 200) {
            this.users = this.userToSelect(res.response.data);
            
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
   
  },
  computed:{
    ...mapGetters(["myUser"])
  }
};
</script>
